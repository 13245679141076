import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { getGraphQlApiURL } from "components/const/variables";
import { AuthProvider } from "components/auth/AuthProvider";

const client = new ApolloClient({
  uri: getGraphQlApiURL(),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Routes />
        </Router>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
