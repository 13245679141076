import { Button, Img, Heading } from "../../components/HomeCom";
import { ReactTable } from "../../components/HomeCom";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";

const tableData = [
  {
    nameHeader: "Bitcoin",
    priceHeader: "36,201.34",
    changeHeader: "+1.718",
    chartHeader: "images/HomePag-img/BTCCha.png",
    buyHeader: "Buy Now",
    bitcoinIcon: "images/HomePag-img/BTC.png",
    bitcoinSymbol: "BTC",
  },
  {
    bitcoinIcon: "images/HomePag-img/ETH.png",
    nameHeader: "Ethereum",
    bitcoinSymbol: "ETH",
    priceHeader: "$2,605.95",
    changeHeader: "+2.04%",
    chartHeader: "images/HomePag-img/BTCCha.png",
    buyHeader: "Buy Now",
  },
  {
    bitcoinIcon: "images/HomePag-img/USDT.png",
    nameHeader: "Tether",
    bitcoinSymbol: "USDT",
    priceHeader: "$939.20",
    changeHeader: "-0.74%",
    chartHeader: "images/HomePag-img/Vector 24.png",
    buyHeader: "Buy Now",
  },
  {
    bitcoinIcon: "images/HomePag-img/XRP.png",
    nameHeader: "Ripple",
    bitcoinSymbol: "XRP",
    priceHeader: "$1.02",
    changeHeader: "+1.20%",
    chartHeader: "images/HomePag-img/XRPCha.png",
    buyHeader: "Buy Now",
  },
  {
    bitcoinIcon: "images/HomePag-img/LINK.png",
    nameHeader: "Chainlink",
    bitcoinSymbol: "LINK",
    priceHeader: "$30.56",
    changeHeader: "-3.84%",
    chartHeader: "images/HomePag-img/Vector 12.png",
    buyHeader: "Buy Now",
  },
];

type TableRowType = {
  nameHeader: string;
  priceHeader: string;
  changeHeader: string;
  chartHeader: string;
  buyHeader: string;
  bitcoinIcon: string;
  bitcoinSymbol: string;
};

export default function PopularCryptocurrenciesSection() {
  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    return [
      tableColumnHelper.accessor("nameHeader", {
        cell: (info) => (
          <div className="flex items-center space-x-1">
            <Img
              src={info.row.original.bitcoinIcon}
              alt="Cryptocurrency Icon"
              className="h-12 w-12"
            />
            <div>
              <Heading size="text2xl" as="p" className="text-white">
                {info.getValue()}
              </Heading>
              <Heading size="textlg" as="p" className="text-indigo-200">
                {info.row.original.bitcoinSymbol}
              </Heading>
            </div>
          </div>
        ),
        header: () => (
          <Heading size="textmd" as="h3" className="text-indigo-200">
            Name
          </Heading>
        ),
        meta: { width: "5%" },
      }),
      tableColumnHelper.accessor("priceHeader", {
        cell: (info) => (
          <Heading size="textxl" as="p" className="text-white">
            {info.getValue()}
          </Heading>
        ),
        header: () => (
          <Heading size="textmd" as="h4" className="text-indigo-200">
            Price
          </Heading>
        ),
        meta: { width: "5%" },
      }),
      tableColumnHelper.accessor("changeHeader", {
        cell: (info) => (
          <Heading size="textlg" as="p" className="text-white">
            {info.getValue()}
          </Heading>
        ),
        header: () => (
          <Heading size="textmd" as="h5" className="text-indigo-200">
            24h Change
          </Heading>
        ),
        meta: { width: "1%" },
      }),
      tableColumnHelper.accessor("chartHeader", {
        cell: (info) => (
          <Img
            src={info.getValue()}
            alt="Cryptocurrency Chart"
            className="h-20 w-full object-contain"
          />
        ),
        header: () => (
          <Heading size="textmd" as="h6" className="text-indigo-200">
            Chart
          </Heading>
        ),
        meta: { width: "20%" },
      }),
      tableColumnHelper.accessor("buyHeader", {
        cell: (info) => (
          <Button
            shape="round"
            className="w-[120px] lg:w-20 border border-white font-bold"
          >
            {info.getValue()}
          </Button>
        ),
        header: () => (
          <Heading size="textmd" as="p" className="text-indigo-200  " >
            Buy
          </Heading>
        ),
        meta: { width: "1%" },
      }),
    ];
  }, []);

  return (
    <section className="relative py-16 ">
      <Img
        src="images/HomePag-img/Eliments.png"
        alt="Decorative Elements"
        className="absolute bottom-0 left-0 right-0 m-auto h-80 w-full object-contain "
      />
      <div className="container mx-60  lg:mx-1 px-4">
        <Heading
          size="heading4xl"
          as="h2"
          className="text-center text-white mb-12"
        >
          Exchange Any of Bitcoin Pairs
        </Heading>
        <div className="overflow-x-auto">
          <ReactTable
            size="xs"
            cellProps={{ className: "border-b border-gray-600" }}
            className="min-w-full"
            columns={tableColumns}
            data={tableData}
          />
        </div>
      </div>
    </section>
  );
}
