import React from "react";
import Select, { Props } from "react-select";

type SelectOptionType = { value: string; label: string };

type SelectProps = Omit<Props, "getOptionLabel"> & {
  className?: string;
  options?: SelectOptionType[];
  isSearchable?: boolean;
  isMulti?: boolean;
  onChange?: (option: any) => void;
  value?: string | SelectOptionType | SelectOptionType[];
  indicator?: React.ReactElement;
  getOptionLabel?: (option: SelectOptionType) => string;
  [x: string]: any;
};

const SelectBox = React.forwardRef<any, SelectProps>(
  (
    {
      children,
      className = "",
      options = [],
      isSearchable = false,
      isMulti = false,
      indicator,
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={className}
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          styles={{
            indicatorsContainer: (provided) => ({
              ...provided,
              padding: 0,
              flexShrink: 0,
              width: "max-content",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 0,
              alignItems: "center",
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "none",
              minHeight: "auto",
              width: "100%",
              "&:hover": {
                border: "0 !important",
              },
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
            }),
            option: (provided) => ({
              ...provided,
              display: "flex",
              minWidth: "max-content",
              width: "100%",
              color: "#000",
            }),
            singleValue: (provided) => ({
              ...provided,
              display: "flex",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
              display: "flex",
            }),
            placeholder: (provided) => ({
              ...provided,
              margin: 0,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            menu: (base) => ({ ...base, minWidth: "max-content", width: "max-content" }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event: any) => {
            return event.target.id === "scrollContainer";
          }}
          {...restProps}
        />
        {children}
      </>
    );
  },
);

export { SelectBox };
