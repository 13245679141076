import "./Buttons.css";
import { Helmet } from "react-helmet";
import {
  Heading,
  Text,
  Img,
  Input,
  SelectBox,
  Switch,
} from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";
import { CloseSVG } from "components/Dashboard/Input/close";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ARBITRAGE_REQUEST } from "components/queries/allQueries";
import { useAuth } from "components/auth/AuthProvider";

const leagueOptions: options[] = [
  { label: "WNBA", value: "wnba" },
  { label: "NBA", value: "nba" },
  { label: "NFL", value: "nfl" },
  { label: "MLB", value: "mlb" },
];

const marketOptions: options[] = [
  { label: "OVER/UNDER", value: "o/u" },
  { label: "SPREAD", value: "spread" },
  { label: "MONEYLINE", value: "moneyline" },
];

const bookOptions: options[] = [
  { label: "Fanduel", value: "fanduel" },
  { label: "Draftkings", value: "draftkings" },
  { label: "ESPNBet", value: "espnbet" },
  { label: "Fanatics", value: "fanatics" },
  { label: "BetMGM", value: "betmgm" },
];

type options = {
  label: string;
  value: string;
};

const table3Data = [
  {
    leagueLabel: "NBA",
    marketLabel: "Rebounds",
    nameLabel: "Michael Porter Jr.",
    handicapLabel: "5.5",
    betTypeLabel: "OVER/UNDER",
    wagerLabel: "205",
    payoutLabel: "210",
    profitLabel: "3.27",
    booksLabel: {
      book1: { name: "fanduel", odds: "-120", link: "#" },
      book2: { name: "draftkings", odds: "+130", link: "#" },
    },
  },
  {
    leagueLabel: "NBA",
    marketLabel: "Assists",
    nameLabel: "Nikola Jokic",
    handicapLabel: "12.5",
    betTypeLabel: "OVER/UNDER",
    wagerLabel: "205",
    payoutLabel: "210",
    profitLabel: "3.27",
    booksLabel: {
      book1: { name: "fanduel", odds: "-120", link: "#" },
      book2: { name: "draftkings", odds: "+130", link: "#" },
    },
  },
  {
    leagueLabel: "NBA",
    marketLabel: "Blocks",
    nameLabel: "Aaron Gordon",
    handicapLabel: "1.5",
    betTypeLabel: "OVER/UNDER",
    wagerLabel: "205",
    payoutLabel: "210",
    profitLabel: "3.27",
    booksLabel: {
      book1: { name: "fanduel", odds: "-120", link: "#" },
      book2: { name: "draftkings", odds: "+130", link: "#" },
    },
  },
  {
    leagueLabel: "NBA",
    marketLabel: "Points",
    nameLabel: "Jamal Murray",
    handicapLabel: "22.5",
    betTypeLabel: "OVER/UNDER",
    wagerLabel: "205",
    payoutLabel: "210",
    profitLabel: "3.27",
    booksLabel: {
      book1: { name: "fanduel", odds: "-110", link: "#" },
      book2: { name: "draftkings", odds: "+130", link: "#" },
    },
  },
];

type Table3RowType = {
  leagueLabel: string;
  marketLabel: string;
  nameLabel: string;
  handicapLabel: string;
  betTypeLabel: string;
  wagerLabel: string;
  payoutLabel: string;
  profitLabel: string;
  booksLabel: booksLabel | string;
};

type booksLabel = {
  book1: book;
  book2: book;
};

type book = {
  name: string;
  odds: string;
  link: string;
};

type ArbitrageData = {
  id: string;
  name: string;
  league: string;
  event: string;
  teamName1?: string;
  teamName2?: string;
  market: string;
  handicap: string;
  amount: string;
  payout: string;
  live: boolean;
  middle: boolean;
  percent: number;
  book1: ArbitrageBook;
  book2: ArbitrageBook;
};

type ArbitrageBook = {
  name: string;
  url: string;
  odds: string;
  bet: string;
};

function arraysMatchUnordered(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}



export default function ArbitragePage() {
  const [searchBarValue1, setSearchBarValue1] = useState("");
  const { tokens } = useAuth();
  const [arbs, setArbs] = useState<Table3RowType[]>(table3Data);
  const [leagueOption, setLeagueOption] = useState<options[]>(leagueOptions);
  const [marketOption, setMarketOption] = useState<options[]>([]);
  const [bookOption, setBookOption] = useState<options[]>([]);
  const [fetchArbitrage] = useLazyQuery(FETCH_ARBITRAGE_REQUEST);

  const handleFetchArbitrage = useCallback(async () => {
    try {
      const response = await fetchArbitrage({
        variables: {
          access_token: tokens.accessToken,
          filter: {
            sports: leagueOption.map((option) => option.value),
            books: bookOption.map((option) => option.value),
            markets: marketOption.map((option) => option.value),
            minimumPercent: 1,
            includeLives: true,
            includeMiddles: true,
          },
        },
      });

      // Handle the response here
      if (response.data.fetchArbitrage.success) {
        const newArbs: Table3RowType[] = [];
        for (const arb of response.data.fetchArbitrage
          .data as ArbitrageData[]) {
          newArbs.push({
            leagueLabel: arb.league.toUpperCase(),
            marketLabel: arb.market,
            nameLabel: arb.name,
            handicapLabel: arb.handicap,
            betTypeLabel: arb.book1.bet + "/" + arb.book2.bet,
            wagerLabel: arb.amount,
            payoutLabel: arb.payout,
            profitLabel:
              (Number(arb.payout) - Number(arb.amount)).toFixed(2) + "",
            booksLabel: {
              book1: {
                name: arb.book1.name,
                odds: arb.book1.odds,
                link: arb.book1.url,
              },
              book2: {
                name: arb.book2.name,
                odds: arb.book2.odds,
                link: arb.book2.url,
              },
            },
          });
        }
        if(!arraysMatchUnordered(newArbs,arbs)) {
          setArbs(newArbs);
        }
        console.log("Fetching arbitrage successful", response.data.login);
      } else {
        console.error("Fetching arbitrage failed:", response.data.login.error);
      }
    } catch (err) {
      console.error("Error fetching arbitrage:", err);
    }
  }, [fetchArbitrage, tokens.accessToken, leagueOption, bookOption, marketOption, arbs]);

  useEffect(() => {
    handleFetchArbitrage();
    const fa = setInterval(handleFetchArbitrage, 3 * 1000); // every 3 seconds
    return () => clearInterval(fa);
  }, [handleFetchArbitrage]);

  useEffect(() => {
    console.log("leagueOption changed", leagueOption);
  }, [leagueOption]);

  useEffect(() => {
    console.log("marketOption changed", marketOption);
  }, [marketOption]);

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_"
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            League
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Market
          </Text>
        ),
        meta: { width: "15%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "15%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("betTypeLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "15%" },
      }),
      table3ColumnHelper.accessor("wagerLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Wager
          </Text>
        ),
        meta: { width: "2%" },
      }),
      table3ColumnHelper.accessor("payoutLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Payout
          </Text>
        ),
        meta: { width: "2%" },
      }),
      table3ColumnHelper.accessor("profitLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          >
            Profit
          </Text>
        ),
        meta: { width: "2%" },
      }),
      table3ColumnHelper.accessor("booksLabel", {
        cell: (info) => (
          <div className="button-container">
            <button
              className="custom-button"
              style={{
                backgroundImage: "url('https://via.placeholder.com/150')",
              }}
              onClick={() =>
                window.open(info.getValue<booksLabel>().book1.link, "_blank")
              }
            >
              {info.getValue<booksLabel>().book1.odds}
            </button>
            <button
              className="custom-button"
              style={{
                backgroundImage: "url('https://via.placeholder.com/150')",
              }}
              onClick={() =>
                window.open(info.getValue<booksLabel>().book2.link, "_blank")
              }
            >
              {info.getValue<booksLabel>().book2.odds}
            </button>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_"
          ></Text>
        ),
        meta: { width: "20%" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Arbitrage Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports arbitrage betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
            <header className="flex items-end justify-center bg-neutral-neutral_bg__day_">
              <div className="mt-6 flex w-[98%] items-start justify-center gap-[30px] md:w-full md:flex-col md:px-5">
                <div className="flex-1 self-center md:self-stretch">
                  <div className="flex flex-col gap-[22px]">
                    <div className="mr-[38px] flex items-center justify-between gap-5 md:mr-0 md:flex-col">
                      <div className="flex w-[84%] justify-between gap-5 md:w-full md:flex-col">
                        <div className="flex w-[50%] items-center justify-between gap-5 md:w-full sm:flex-col">
                          <Input
                            name="Search Field"
                            placeholder="Search"
                            value={searchBarValue1}
                            onChange={(e) => setSearchBarValue1(e.target.value)}
                            prefix={
                              <Img
                                src="/Images/Dashboard-img/search.png"
                                alt="Contrast"
                                className="h-[20px] w-[20px]"
                              />
                            }
                            suffix={
                              searchBarValue1.length > 0 ? (
                                <Img
                                  src="Images/microphone-2.png"
                                  alt="Menu"
                                  onClick={() => setSearchBarValue1("")}
                                  className="h-[20px] w-[20px]"
                                />
                              ) : null
                            }
                            className="flex h-[48px] w-[66%] items-center justify-center gap-2 rounded-[12px] bg-neutral-neutral_100__day_ px-[18px] text-[14px] font-semibold text-neutral-neutral_300__day_ sm:w-full"
                          />
                          <div className="flex flex-wrap items-center">
                            <a href="./#">
                              <Img
                                src="Images/export.png"
                                alt="External Link"
                                className="h-[16px] w-[16px]"
                              />
                            </a>
                            <Heading
                              size="text_bold"
                              as="p"
                              className="ml-2.5 text-[14px] font-semibold text-neutral-neutral_500__day_ "
                            >
                              Browse
                            </Heading>
                            <Img
                              src="/Images/Dashboard-img/arrow-down.png"
                              alt="Dropdown Arrow"
                              className="ml-[50px] h-[12px] w-[12px]"
                            />
                          </div>
                        </div>

                        <div className="flex w-[18%] items-center justify-between gap-5 md:w-full">
                          <div className="flex h-[24px] w-[24px] items-start justify-end bg-[url(/public/Images/Dashboard-img/Message.png)]  bg-cover bg-no-repeat md:h-auto">
                            <div className="mb-4 h-[8px] w-[8px] rounded bg-accents-fuchsia" />
                          </div>
                          <div className="flex h-[24px] w-[24px] flex-col items-end bg-[url(/public/Images/Dashboard-img/notice.png)] bg-cover bg-no-repeat md:h-auto">
                            <div className="mb-4 h-[8px] w-[8px] rounded bg-accents-fuchsia" />
                          </div>
                          <div className="flex flex-col">
                            <a href="./#">
                              <Img
                                src="/Images/Dashboard-img/topbar.png"
                                alt="Profile Image"
                                className="h-[48px] rounded-[24px] object-cover"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ml-4 h-px bg-neutral-neutral_border__day_ md:ml-0" />
                  </div>
                </div>
              </div>
            </header>
            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
                <div className="flex md:flex-col">
                  <Input
                    name="Search Bar"
                    placeholder="Enter your keyword..."
                    value={searchBarValue1}
                    onChange={(e) => setSearchBarValue1(e.target.value)}
                    prefix={
                      <Img
                        src="images/img_contrast.svg"
                        alt="Contrast"
                        className="my-1 h-[12px] w-[20px]"
                      />
                    }
                    suffix={
                      searchBarValue1.length > 0 ? (
                        <CloseSVG
                          onClick={() => setSearchBarValue1("")}
                          height={12}
                          fillColor="#7d7d8fff"
                        />
                      ) : null
                    }
                    className="flex h-[48px] w-[18%] items-center justify-center gap-2 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[14px] text-blue_gray-300 md:w-full md:px-5"
                  />
                  <div className="relative h-[48px] flex-1">
                    <div className="relative right-3 m-auto flex flex-1 px-[30px] sm:px-5">
                      <Input
                        name="Search Bar"
                        placeholder="Min Profit %"
                        className="flex h-[48px] w-[100%] items-center justify-center gap-2 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[14px] text-blue_gray-300 md:w-full md:px-5"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 justify-center md:self-stretch md:px-5">
                  <SelectBox
                    indicator={
                      <Img
                        src="images/img_arrowdown_neutral_neutral_500_[day].svg"
                        alt="Arrow Down"
                        className="h-[12px] w-[10px]"
                      />
                    }
                    name="League Dropdown"
                    placeholder="League"
                    options={leagueOptions}
                    onChange={(opt: options[]) => setLeagueOption(opt)}
                    value={leagueOption}
                    isMulti={true}
                    isSearchable={true}
                    className="ml-4 flex h-[48px] w-[25%] gap-4 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-3 text-[14px] text-neutral-neutral_500__day_ md:ml-0 md:w-full md:px-5"
                  />

                  <SelectBox
                    indicator={
                      <Img
                        src="images/img_arrowdown_neutral_neutral_500_[day].svg"
                        alt="Arrow Down"
                        className="h-[12px] w-[10px]"
                      />
                    }
                    name="Market Dropdown"
                    placeholder="Market"
                    options={marketOptions}
                    onChange={(opt: options[]) => setMarketOption(opt)}
                    value={marketOption}
                    isMulti={true}
                    isSearchable={true}
                    className="ml-4 flex w-[25%] h-[48px] gap-4 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-3 text-[14px] text-neutral-neutral_500__day_ md:ml-0 md:w-full md:px-5"
                  />

                  <SelectBox
                    indicator={
                      <Img
                        src="images/img_arrowdown_neutral_neutral_500_[day].svg"
                        alt="Arrow Down"
                        className="h-[12px] w-[10px]"
                      />
                    }
                    name="Books Dropdown"
                    placeholder="Books"
                    options={bookOptions}
                    onChange={(opt: options[]) => setBookOption(opt)}
                    value={bookOption}
                    isMulti={true}
                    isSearchable={true}
                    className="ml-4 flex w-[25%] h-[48px] gap-4 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-3 text-[14px] text-neutral-neutral_500__day_ md:ml-0 md:w-full md:px-5"
                  />
                  <div className="flex flex-1 justify-center md:self-stretch md:px-5">
                    <div className="relative z-[1] ml-[-30px] flex items-start gap-3 p-3">
                      <Switch value={true} />
                      <Text
                        size="textxs"
                        as="p"
                        className="self-center text-[14px] font-normal text-red-900"
                      >
                        Live
                      </Text>
                    </div>
                  </div>

                  <div className="flex w-[12%] justify-center py-2.5 md:w-full md:px-5">
                    <div className="flex w-full items-start justify-center gap-3">
                      <Switch value={true} />
                      <Text
                        size="textxs"
                        as="p"
                        className="self-center bg-gradient bg-clip-text text-[14px] font-normal text-transparent"
                      >
                        Middles
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="rounded-[16px] border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={arbs}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
