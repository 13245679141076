import { Img, Heading } from "../../components/HomeCom";
import ClientStatistics from "../../components/HomeCom/ClientStatistics";
import DecentralizedIdentityVerification from "../../components/HomeCom/DecentralizedIdentityVerification";
import React from "react";

export default function FeaturesSection() {
  return (
    <>
      {/* Features section */}
      <div className="relative flex flex-col items-center bg-gray-900  py-16 lg:py-12 md:py-8 sm:py-6 -mx-5 px-1">
      <div className="container-xs h-auto w-full mx-auto ">
        <div className=" mx-auto px-1 lg:px-2 md:px-4">
          {/* Heading Section */}
          <div className="text-center mb-16 lg:mb-12 md:mb-8 sm:mb-6">
            <Heading
              size="heading4xl"
              as="h2"
              className="text-white font-bold leading-tight tracking-tight md:text-3xl sm:text-2xl"
            >
              The Best Crypto Features You Won't Find Anywhere Else
            </Heading>
          </div>

          {/* Features List */}
          <div className="grid grid-cols-2 lg:grid-cols-1 gap-12">
            <div className=" rounded-lg shadow-lg">
              <DecentralizedIdentityVerification className="bg-blue_gray-900_01" imageSrc="images/HomePag-img/Vector 6.png"/>
            </div>
            <div className=" p-2 rounded-lg shadow-lg flex bg-blue_gray-900_01 flex-col items-center">
            <Img
                src="images/HomePag-img/Chart 05.png"
                alt="Chart Image"
                className="w-50 h-80 object-cover rounded-lg"
              />
              <div className="mt-50 text-center">
                <Heading
                  size="heading2xl"
                  as="h3"
                  className="text-white font-bold leading-tight"
                >
                  Comprehensive Asset Selection
                </Heading>
                <p className="mt-2 text-gray-400">
                  Our platform offers a thorough and effective solution that covers all aspects of your trading needs, ensuring a comprehensive experience.
                </p>
              </div>
            </div>
            <div className="p-6 rounded-lg bg-blue_gray-900_01 shadow-lg">
              <DecentralizedIdentityVerification
                headingText="Instant Deposits and Withdrawals"
                descriptionText="Trade swiftly with immediate access to your funds. No waiting, just seamless transactions."
                imageSrc="images/HomePag-img/Vector-6.png"
              />
            </div>
            
          </div>

          {/* Additional Features */}
          <div className="mt-16 p-8 rounded-lg bg-blue_gray-900_01 shadow-lg">
  <div className="flex  md:flex-row items-center gap-8">
    {/* Text Content */}
    <div className="flex flex-col items-start w-full md:w-1/2">
      <Heading
        size="heading2xl"
        as="h3"
        className="text-white font-bold"
      >
        Competitive Trading Fees & 24/7 Support
      </Heading>
      <p className="mt-2 text-gray-400">
        Benefit from our competitive trading fees while enjoying round-the-clock support. Trade efficiently and get assistance whenever you need.
      </p>
    </div>
    
    {/* Image */}
    <div className="w-full md:w-1/2 h-auto md:h-96 rounded-lg">
      <Img
        src="images/HomePag-img/trading.png"
        alt="Trading"
        className="w-50 h-50 object-cover rounded-lg"
      />
    </div>
  </div>
</div>



          {/* Client Statistics */}
          <div className="container mx-auto mt-20 px-4">
  <div className="flex flex-wrap gap-8 justify-center lg:grid lg:grid-cols-4 lg:gap-6">
  <ClientStatistics />
    <Img
      src="images/HomePag-img/Group.png"
      alt="Image"
      className="h-auto lg:w-0 object-contain"
    />
    <ClientStatistics clientCount="99%" clientDescription="Customer Satisfaction" />
    <Img
      src="images/HomePag-img/Group.png"
      alt="Image"
      className=" h-auto lg:w-0 object-contain"
    />
    <ClientStatistics clientCount="30k+" clientDescription="Successful Projects" />
    <Img
      src="images/HomePag-img/Group.png"
      alt="Image"
      className="h-auto lg:w-0 object-contain"
    />
    <ClientStatistics clientCount="100%" clientDescription="Security Certification" />
  </div>
</div>


        </div>
      </div>
      </div>
    </>
  );
}
