import { Button, Img, Text } from "./.."; 
import React from "react";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  className?: string;
}

export default function Sidebar1({ ...props }: Props) {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleNavigation = () => {
    navigate('/PaidOptimizer');
  };
  
  const handleNavigation1 = () => {
    navigate('/PaidDashboard');
  };

  const handleNavigation2 = () => {
    navigate('/ArbitragePage');
  };

  return (
    <Sidebar
      {...props}
      width="258px"
      collapsedWidth="110px"
      backgroundColor= "#ffffff" 
      collapsed={collapsed}
      rootStyles={{ ['.${sidebarClasses.container}']: { gap: 34 } }}
      className={`${props.className} flex flex-col h-screen pt-7 gap-[34px] top-0 px-4 sm:pt-5 sm:px-5 sticky overflow-auto transition-all duration-300 ease-in-out`}
    >



<div className="relative flex justify-center mb-5">
  {collapsed ? (
    <Img src="/Images/Dashboard-img/LogoArb.png" alt="Logo Colapsado" className="h-15" />
  ) : (
    <Img src="/Images/LogoDark.png" alt="Logo Expandido" className="h-15" />
  )}
  
  <div className="flex items-center gap-5 ml-auto">
    <h2 className={`text-xl ${collapsed ? 'hidden' : 'block'}`}></h2>
  </div>

  <Button 
    onClick={collapseSidebar} 
    className="absolute right-1 flex h-[30px] w-[30px] items-center justify-center rounded-[16px] border border-solid border-neutral-neutral_border___day_ bg-neutral-neutral_bg__day  shadow-sm"
    style={{ top: '50%', transform: 'translateY(-50%)' }} // Para centrar verticalmente
  >
    <Img src={collapsed ? "/Images/Dashboard-img/arrow-right.png" : "/Images/Dashboard-img/arrow-left.png"} />
  </Button>
</div>





      <Menu
        menuItemStyles={{
          button: {
            padding: "16px",
            gap: "1px", 
            color: "#7d7d8f", 
            fontWeight: 600, 
            fontSize: "14px", 
            marginBottom: "10px", // Añadir margen inferior
            borderRadius: "12px",
            '&:hover, &-ps-active': { color: "#ffffff", background: "linear-gradient(220.38deg, #2e5bbe, #78319d)" },
          },
        }}
        className="w-full self-stretch"
      >
        <MenuItem 
          onClick={handleNavigation1} 
          icon={<Img src="/Images/Dashboard-img/Dashboard.png" alt="Dashboard Icon" className="h-[22px] w-[22px]" />}
          className={` ${
            location.pathname === '/PaidDashboard' 
              ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px] ' 
              : ''
          } `}
        
        >
          {!collapsed && "Dashboard"}
        </MenuItem>
        <MenuItem 
          onClick={handleNavigation} 
          icon={<Img src="/Images/Dashboard-img/Shop.png" alt="Shop Icon" className="h-[22px] w-[22px]" />}
          className={location.pathname === '/PaidOptimizer' ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px] ' : ''}
        >
          {!collapsed && "Optimizer"}
        </MenuItem>
        <MenuItem 
          onClick={handleNavigation2} 
          icon={<Img src="/Images/Dashboard-img/Arbitrage.png" alt="Arbitrage Icon" className="h-[22px] w-[22px]" />}
          className={location.pathname === '/ArbitragePage' ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px] ' : ''}
        >
          {!collapsed && "Arbitrage"}
        </MenuItem>
        <MenuItem icon={<Img src="/Images/Dashboard-img/Models.png" alt="Models Icon" className="h-[22px] w-[22px]" />}>
          {!collapsed && "Models"}
        </MenuItem>
        <MenuItem icon={<Img src="/Images/Dashboard-img/ToolsPag.png" alt="Tools Icon" className="h-[22px] w-[22px]" />}>
          {!collapsed && "Tools Page (EV)"}
        </MenuItem>
        <MenuItem icon={<Img src="/Images/Dashboard-img/Referrals.png" alt="Referrals Icon" className="h-[22px] w-[22px]" />}>
          {!collapsed && "Referrals"}
        </MenuItem>
      </Menu>

      {!collapsed && <div className="h-px self-stretch  bg-neutral-neutral_border__day_" />}

      {!collapsed ? (
         <div className=" w-[100%] flex-col py-10">
        <div className="flex flex-col items-end justify-center self-stretch rounded-[12px] bg-neutral-neutral_border__day_ px-3 py-5">
          <div className="flex w-[88%] flex-col gap-1">
            <Img src="/Images/Dashboard-img/Paid.png" alt="Promo Image" className="h-[118px] w-[74%] object-contain" />
            <div className="relative h-[116px]">
              <div className="absolute bottom-0 left-0 right-0 m-auto flex flex-1 flex-col items-start gap-5"> 
                <div className="relative h-[48px] content-center self-stretch">
                  <Text
                    size="description" 
                    as="p"
                    className="flex-1 text-center text-[12px] font-normal leading-4 text-neutral-neutral_1100__day_"
                  >
                    <span>
                      Unlock more information now <br />
                      by upgrading to&nbsp;
                    </span>
                    <span className="font-bold">PRO</span>
                  </Text>
                  <Img
                    src="/Images/Dashboard-img/image 1025.png"
                    alt="Feature Image"
                    className="absolute left-[135px] bottom-[3px] right-[22px] m-auto h-[20px] object-cover"
                  />
                </div>
                <Button className="ml-8 flex h-[32px] min-w-[122px] items-center justify-center rounded bg-brand-brand_1 px-3.5 text-center text-[12px] font-semibold text-neutral-neutral_bg__day_">
                  Free 7 Day Trial
                </Button>
              </div>
              <Img src="/Images/Dashboard-img/Group 237535.png" alt="Grid Image" className="absolute right-[-1px] top-0 m-auto h-[30px]" />
            </div>
            </div>
          </div>
        </div>
      ) : null }
      
    </Sidebar>
    
  );
}
