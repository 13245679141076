import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button } from "../../../components/Dashboard";
import Footer from "../../../components/Dashboard/Footer";
import Header from "../../../components/Dashboard/Header";
import { ReactTable } from "../../../components/Dashboard";
import Sidebar1 from "../../../components/Dashboard/Sidebar1";
import DashboardSection from "./DashboardSection";
import PaidDashboardMainSection from "./PaidDashboardMainSection";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";

const table2Data = [
  { playHeader: "Lebron 022.5 points", lineHeader: "-200", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-250", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-300", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-280", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-140", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-590", logicHeader: "+EV" },
];

type Table2RowType = {
  playHeader: string;
  lineHeader: string;
  logicHeader: string;
};

export default function PaidOptimizer() {
  const table2Columns = React.useMemo(() => {
    const table2ColumnHelper = createColumnHelper<Table2RowType>();
    return [
      table2ColumnHelper.accessor("playHeader", {
        cell: (info) => (
          <Text as="p" className="pl-[26px] text-[14px] font-normal text-neutral-neutral_1100__day_ sm:pl-5">
            {info.getValue<string>()}
          </Text>
        ),
        header: () => (
          <Text as="p" className="pb-2.5 pl-[26px] text-left text-[14px] font-normal text-neutral-neutral_400__day_">
            Play
          </Text>
        ),
        meta: { width: "280px" },
      }),
      table2ColumnHelper.accessor("lineHeader", {
        cell: (info) => (
          <Text as="p" className="text-[14px] font-normal text-neutral-neutral_1100__day_">
            {info.getValue<string>()}
          </Text>
        ),
        header: () => (
          <Text as="p" className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_400__day_">
            Line
          </Text>
        ),
        meta: { width: "132px" },
      }),
      table2ColumnHelper.accessor("logicHeader", {
        cell: (info) => (
          <Text as="p" className="text-[14px] font-normal text-neutral-neutral_1100__day_">
            {info.getValue<string>()}
          </Text>
        ),
        header: () => (
          <Text as="p" className="pb-2.5 text-left text-[14px] font-normal text-neutral-neutral_400__day_">
            Logic
          </Text>
        ),
        meta: { width: "110px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Elite Dashboard - Advanced Betting Analytics</title>
        <meta
          name="description"
          content="Elevate your betting game with the Elite Dashboard. Get access to exclusive Arbitrage opportunities, Model Projections, and +EV Plays for top-tier betting performance."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="flex-1 bg-neutral-neutral_100__day_ relative">
          <div className="relative h-[96px] md:h-auto">
            <Header />

          </div>
          <div className="mr-8 ml-5 flex items-start gap-[22px] md:mr-0">
            <div className="flex flex-1 flex-col gap-[18px]">
              <div className="flex flex-col gap-6">
                {/* Dashboard Section */}
                <DashboardSection />
                {/* Paid Dashboard Main Section */}
                <PaidDashboardMainSection />
              </div>
              <div className="ml-1 grid grid-cols-2 gap-5 0md:ml-0">
                <div className="mr-1.5 flex gap-[18px] md:mr-0 md:flex-col">
                  <div className="flex w-[100%] flex-col gap-6 rounded-[16px] border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-[22px] md:w-full md:px-5 sm:p-5">
                    <div className="flex items-start justify-between gap-5">
                      <Heading
                        size="subtitle_semi_bold"
                        as="h4"
                        className="self-center text-[16px] font-semibold text-neutral-neutral_1100__day_"
                      >
                        Arbitrage T-7 Results
                      </Heading>
                      <Img src="/Images/Dashboard-img/Toggle.png" alt="Toggle Icon" className="mr-[172px] mt-1 h-[12px]" />
                    </div>
                    <div>
                      <div className="mx-2 flex flex-wrap justify-between gap-5 md:mx-0">
                        {["$45", "$87", "$131", "$39", "$142", "$163", "$84"].map((amount, index) => (
                          <Heading key={index} as="p" className="text-[16px] font-medium tracking-[-0.14px] text-accents-green">
                            {amount}
                          </Heading>
                        ))}
                      </div>
                      <div className="flex gap-[71px] sm:flex-col">
                        {Array(7).fill(0).map((_, index) => (
                          <div key={index} className="flex-1 rounded-[10px] bg-neutral-neutral_border__day_ sm:self-stretch">
                            <div className="mt-[146px] h-[80px] w-[46px] rounded-[10px] bg-brand-brand_1" />
                          </div>
                        ))}
                      </div>
                      <div className="mt-2.5 flex flex-wrap justify-between gap-5">
                        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(day => (
                          <Text key={day} size="description" as="p" className="text-[12px] font-normal text-neutral-neutral_400__night_">
                            {day}
                          </Text>
                        ))}
                      </div>

                    </div>
                    <Heading 
                    size="heading_h2"
                    as="h2"
                    className="mb-2 text-[32px] font-extrabold leading-[38px] text-accents-green md:text-[30px] sm:text-[28px]"
                  >
                    <span className="text-accents-green">$691&nbsp;</span> 
                    <span className="text-black-900">of&nbsp;</span> 
                    <span className="text-accents-green">arbitrage profit</span> 
                    <span className="text-black-900">&nbsp;in the last&nbsp;</span> 
                    <span className="text-accents-green">7 days</span> 
                    <span className="text-black-900">.&nbsp;</span>
                  </Heading>
                  </div>
                </div>
                <div className="flex flex-1 flex-col w-auto gap-8 rounded-[16px] border border-solid border-neutral-neutral_border_day_ bg-neutral-neutral_bg__day_ p-10 md:self-stretch md:px-5">
                  <div className=" mt-.5 flex flex-col items-start justify-center gap-2.5 md:ml-0">
                    <Heading
                      size="subtitle_semi_bold"
                      as="h6"
                      className="text-[16px] font-semibold text-neutral-neutral_1100__day_"
                    >
                      Parlay Builder
                    </Heading>
                    <div className="mr-2 h-px self-stretch bg-gray-100 md:mr-0" />
                  </div>
                  <div className="mx-1.5 md:mx-0">
                  <ReactTable
                      size="sm"
                      bodyProps={{ className: "" }}
                      cellProps={{ className: "border-neutral-neutral_border_day_ border-b border-solid" }}
                      className="mb-[26px]"
                      columns={table2Columns}
                      data={table2Data}
                    />
                  </div>
                </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>

    </>
  );
}
