import {useRoutes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import LightAuthenticationLogin from "./pages/LightAuthenticationLogin";
import Signup from "./pages/Signup";
import Payment from "./pages/Signup/Payment";
import VerifyAccount from "./pages/Signup/VerifyAccount";
import ResetPass from "./pages/Signup/ResetPass";
import AdminArea from "./pages/AdminArea";
import HomePage from "./pages/HomePage";
import ArbitragePage from "pages/Dashboard/Arbitrage";
import PaidOptimizer from "pages/Dashboard/Paid-Opti";
import PaidDashboard from "pages/Dashboard/Paid-Dash";

const ProjectRoutes = () => {
let element = useRoutes ([
{path: "/", element: <HomePage /> },
{path: "*", element: <NotFound /> },
{    
    path: "lightauthenticationlogin", 
    element: <LightAuthenticationLogin />,
},
{    
    path: "Signup", 
    element: <Signup />,
},
{    
    path: "Payment", 
    element: <Payment />,
},
{    
    path: "VerifyAccount", 
    element: <VerifyAccount />,
},
{    
    path: "ResetPass", 
    element: <ResetPass />,
},
{    
    path: "AdminArea", 
    element: <AdminArea />,
},
{    
    path: "ArbitragePage", 
    element: <ArbitragePage />,
},
{    
    path: "PaidOptimizer", 
    element: <PaidOptimizer />,
},
{    
    path: "PaidDashboard", 
    element: <PaidDashboard />,
},
]);

return element;
};

export default ProjectRoutes;
