import { Img, Heading } from "../../../components/Dashboard";
import ArbitrageRow from "../../../components/Dashboard/ArbitrageRow";
import UserProfile from "../../../components/Dashboard/UserProfile";
import React, { Suspense } from "react";

const arbitrageGrid = [
  { arbitrageText: "Arbitrage" },
  { arbitrageText: "Discrepancy Play" },
  { arbitrageText: "Arbitrage" }, 
  { arbitrageText: "Arbitrage" },
  { arbitrageText: "Models Projection" }, 
  { arbitrageText: "Discrepancy Play" }, 
  { arbitrageText: "Arbitrage" }, 
  { arbitrageText: "Arbitrage" }, 
  { arbitrageText: "Arbitrage" },
];


export default function PaidOptimizerMainSection() {
  return (
    <>
      {/* Paid dashboard main section */}
      <div className="flex gap-4 md:flex-col">
        <div className="flex-1 md:self-stretch  md:px-5">
          <div className="flex flex-col gap-3.5  rounded-[16px] border-[.5px] border-solid border-neutral-neutral_border___day_ bg-neutral-neutral_bg__day_ py-8">
            <div className="flex flex-col items-start gap-5">
              <Heading
                size="subtitle_semi_bold" 
                as="h2"
                className="ml-6 text-[16px] font-semibold text-neutral-neutral_1100__day_ md:m-0"
              >
                Today's Top Plays
              </Heading>
              <div className="h-px w-full self-stretch bg-gray-100" />
            </div>
            <div className="mx-3.5 grid grid-cols-3 justify-center gap-6 md:mx-0 md:grid-cols-2 sm:grid-cols-1">
              <Suspense fallback={<div>Loading feed...</div>}>
                {arbitrageGrid.map((d, index) => (
                  <ArbitrageRow {...d} key={"arbitrageGrid" + index} className="bg-teal-300_26 " />
                ))}
              </Suspense>
            </div>
          </div>
        </div>
        <div className="w-[23%] md:w-full md:px-5">
          <div className="relative h-[450px] content-end rounded-[16px] border-[0.5px] border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ md:h-auto">
            <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-max flex-1">
              <div className="flex flex-col items-center">
                <div className="mx-6 flex items-center justify-between gap-5 self-stretch md:mx-0">
                  <Heading
                    size="subtitle_semi_bold" 
                    as="h3"
                    className="text-[16px] font-semibold text-neutral-neutral_1100__day_"
                  >
                    Live Feed
                  </Heading>
                  <Img src="/Images/Dashboard-img/Toggle.png" alt="Toggle Icon" className="h-[12px]" />
                </div>
                <div className="mt-5 h-px w-full self-stretch bg-blue_gray-50_99" />
                <Img
                  src="/Images/Dashboard-img/Toggle.png"
                  alt="Group Image"
                  className="mr-12 h-px w-[30%] self-end object-contain md:mr-0"
                />
              </div>
              <Img
                src="/Images/Dashboard-img/image 1.png"
                alt="Image One"
                className="relative ml-2.5 mr-2 mt-[-10px] h-[390px] w-[356px]  md:mx-0 md:h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
