import { Img, Input } from "./..";
import React from "react";

interface Props {
  className?: string;
}

export default function Header({ ...props }: Props) {
  const [searchBarValue, setSearchBarValue] = React.useState("");

  return (
    <header {...props} className={`${props.className} flex items-end mx-auto bg-neutral-neutral_bg__day_ flex-1`}>
      <div className="mt-[18px] flex w-full flex-col items-start gap-3.5">
        <div className="mx-auto flex w-full max-w-[1830px] items-center justify-between gap-5 md:flex-col md:px-5">
          <div className="flex w-[86%] justify-between gap-5 md:w-full sm:flex-col">
            <Input
              name="searchField"
              placeholder="Search"
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
              prefix={
                <Img src="/Images/Dashboard-img/search.png" alt="Contrast" className="h-[20px] w-[20px]" />
              }
              suffix={
                searchBarValue.length > 0 ? (
                  <Img
                    src="images/img_menu.svg"
                    alt="Clear Search"
                    onClick={() => setSearchBarValue("")}
                    className="h-[20px] w-[20px]"
                  />
                ) : null
              }
              className="flex h-[48px] w-[38%] items-center justify-center gap-2 rounded-[12px] bg-neutral-neutral_100__day_ px-[18px] text-[14px] font-semibold text-neutral-neutral_300__day_ sm:w-full"
            />
            <a href="./#">
              <Img
                src="/Images/Dashboard-img/topbar.png"
                alt="Profile Link"
                className="h-[48px] w-[48px] rounded-[24px] object-cover sm:w-full"
              />
            </a>
          </div>
        </div>
        <div className="h-px w-[84%] self-end bg-neutral-neutral_border__day_" />
      </div>
    </header>
  );
}
