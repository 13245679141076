import React from "react";

// Define las formas disponibles para los botones.
const shapes = {
  square: "rounded-[0px]",
  round: "rounded-[24px] ",
  circle: "rounded-[50%]",
} as const;

// Define los estilos de variante para los botones.
const variants = {
  fill: {
    gray_900_02: "bg-gray-900_02 text-white-a700_01",
    yellow_900: "bg-yellow-900",
    green_700: "bg-green-700",
    indigo_A200_01: "bg-indigo-a200_01",
    deep_orange_300: "bg-deep_orange-300",
    blue_500: "bg-blue-500",
    blue_800: "bg-blue-800",
    white_A700_01: "bg-white-a700_01",
    deep_purple_A400: "bg-deep_purple-a400 text-white-a700_01",
    teal_A400: "bg-teal-a400 text-gray-900_02",
    blue_gray_900_01: "bg-blue_gray-900_01",
  },
  gradient: {
    blue_A200_purple_A200: "bg-gradient text-white-a700_01",
  },
} as const;

// Define los tamaños disponibles para los botones.
const sizes = {
  lg: "h-[52px] px-3",
  xs: "h-[40px] px-[30px] text-[16px]",
  sm: "h-[40px] px-2.5",
  "3xl": "h-[70px] px-[34px] text-[18px]",
  "2xl": "h-[66px] px-3",
  xl: "h-[56px] px-4",
  "4xl": "h-[72px]",
  md: "h-[50px] px-5 text-[18px]",
} as const;

// Tipo para las propiedades de color dependiendo de la variante.

// Define el tipo de las propiedades del componente Button.
type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> & 
Partial<{
  className: string;
  leftIcon: React.ReactNode;
  rightIcon: React.ReactNode;
  onClick: () => void;
  shape: keyof typeof shapes;
  variant: keyof typeof variants | null;
  size: keyof typeof sizes;
  color:string; // Ajuste aquí
}>;

// Componente funcional Button.
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  color = "deep_purple_A400",
  size = "md",
  ...restProps
}) => {
  return (
    <button
    className={` ${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${shape ? shapes[shape] : ''} ${size ? sizes[size] : ''} ${variant ? variants[variant]?.[color as keyof typeof variants[typeof variant]] : ''}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
