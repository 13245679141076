import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Button, Img, Input, Heading } from "../../components";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { SUBMIT_REGISTER_REQUEST } from "components/queries/allQueries";
import md5 from "md5";

export default function Signup() {
  const [clickCount, setClickCount] = useState(0); // Estado para contar los clics
  const navigate = useNavigate();

  const handleImageClick = () => {
    const newClickCount = clickCount + 1;
    setClickCount(newClickCount);

    if (newClickCount >= 10) {
      navigate("/AdminArea"); // Redirige a /login después de 5 clics
    }
  };

  const [username, setUsername] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const [phone, setPhone] = useState<String>("");
  const [email, setEmail] = useState<String>("");
  const [firstName, setFirstName] = useState<String>("");
  const [lastName, setLastName] = useState<String>("");
  const [sendRegisterRequest, { loading, error, data }] = useLazyQuery(
    SUBMIT_REGISTER_REQUEST,
  );

  const handleSignUp = () => {
    sendRegisterRequest({
      variables: {
        username: username,
        password: md5(password.toString()),
        phone: phone,
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error! {error.message}</p>;

  if (data && data.register) {
    if (data.register.success) navigate("/lightauthenticationlogin"); // Redirige al usuario a la página de pago
  }

  return (
    <>
      <Helmet>
        <title>Sign Up - Create Your Account</title>
        <meta
          name="description"
          content="Create an account to enjoy our services. Fill in your details to get started."
        />
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-20 bg-neutral-neutral_border__day_ md:gap-16 sm:gap-10">
        <Header />
        <div className="flex w-full max-w-md min-w-[320px] min-h-[500px] items-center justify-center bg-custom-color border border-neutral-neutral_border__day_ rounded-2xl p-8 md:w-full sm:p-5">
          <div className="w-full flex flex-col items-center gap-6">
            <div className="flex flex-col items-center gap-2 md:w-full">
              <Img
                src="images/Launching 1.png"
                alt="Launch"
                className="h-16 w-16 "
                onClick={handleImageClick} // Maneja el clic en la imagen
              />
              <div className="text-center">
              <Heading size="heading_h5" as="h1" className="!text-neutral-neutral_1100__day_  ">
                  Create an Account
                </Heading>
                <Text as="p" className="text-neutral-neutral_500__day_ font-light">
                  Insane Plays Incoming!
                </Text>
              </div>
            </div>

            <div className="flex gap-2">
              <Img
                src="images/SignUp-img/image 1505.png"
                alt="Decoration"
                className="h-8 w-8 mt-4"
              />
              <Img
                src="images/SignUp-img/item.png"
                alt="Item"
                className="h-12 w-80"
              />
              <Img
                src="images/SignUp-img/image 1500.png"
                alt="Decoration"
                className="h-8 w-8 mt-4"
              />
            </div>

            <div className="flex flex-col items-center gap-4 self-stretch">
              <div className="flex gap-4">
              <div className="flex flex-col font-extralight">
                  <Text as="p" className="font-light mb-1">
                    Username
                  </Text>
                  <Input
                    shape="round"
                    type="text"
                    name="Username Field"
                    placeholder="Username"
                    onChange={(u) => setUsername(u.target.value)}
                    suffix={
                      <Img
                        src="images/SignUp-img/user.png"
                        alt="User Icon"
                        className="h-4 w-4"
                      />
                    }
                    className="flex items-center border border-solid border-neutral-neutral_border_day_ py-2 px-4"
                  />
                </div>

                <div className="flex flex-col font-extralight">
                  <Text as="p" className="font-light mb-1">
                    Your Name
                  </Text>
                  <Input
                    shape="round"
                    type="text"
                    name="Name Field"
                    placeholder="First Name"
                    onChange={(u) => setFirstName(u.target.value)}
                    suffix={
                      <Img
                        src="images/SignUp-img/user.png"
                        alt="User Icon"
                        className="h-4 w-4"
                      />
                    }
                    className="flex items-center border border-solid border-neutral-neutral_border_day_ py-2 px-4"
                  />
                </div>

                <div className="flex flex-col font-extralight">
                  <Text as="p" className="font-light mb-1">
                    Last Name
                  </Text>
                  <Input
                    shape="round"
                    type="text"
                    name="Last Field"
                    placeholder="Last Name"
                    onChange={(u) => setLastName(u.target.value)}
                    suffix={
                      <Img
                        src="images/SignUp-img/user.png"
                        alt="User Icon"
                        className="h-4 w-4"
                      />
                    }
                    className="flex items-center border border-solid border-neutral-neutral_border_day_ py-2 px-4"
                  />
                </div>
              </div>

              <div className="flex flex-col font-extralight items-start gap-1 self-stretch">
                <Text as="p" className="font-light">
                  E-mail
                </Text>
                <Input
                  shape="round"
                  type="email"
                  name="Email Field"
                  placeholder="Email"
                  onChange={(u) => setEmail(u.target.value)}
                  suffix={
                    <Img
                      src="images/sms.png"
                      alt="SMS Icon"
                      className="h-4 w-4"
                    />
                  }
                  className="self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>

              <div className="flex flex-col items-start gap-1 font-extralight self-stretch">
                <Text as="p" className="font-light">
                  Phone Number
                </Text>
                <Input
                  shape="round"
                  type="tel"
                  name="Phone Field"
                  placeholder="Phone Number"
                  onChange={(u) => setPhone(u.target.value)}
                  suffix={
                    <Img
                      src="images/SignUp-img/call.png"
                      alt="Call Icon"
                      className="h-4 w-4"
                    />
                  }
                  className="self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>

              <div className="flex flex-col font-extralight items-start gap-1 self-stretch">
                <Text as="p" className="font-light">
                  Password
                </Text>
                <Input
                  shape="round"
                  type="password"
                  name="Password Field"
                  placeholder="Password"
                  onChange={(u) => setPassword(u.target.value)}
                  suffix={
                    <Img
                      src="images/eye.png"
                      alt="Eye Icon"
                      className="h-4 w-4"
                    />
                  }
                  className="self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>

              <div className="flex flex-col font-extralight items-start gap-1 self-stretch">
                <Text as="p" className="font-light">
                  Confirm Password
                </Text>
                <Input
                  shape="round"
                  type="password"
                  name="Confirm Password Field"
                  placeholder="Confirm Password"
                  suffix={
                    <Img
                      src="images/eye.png"
                      alt="Eye Icon"
                      className="h-4 w-4"
                    />
                  }
                  className="self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>

              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="blue_A200_purple_A200"
                className="self-stretch font-semibold"
                onClick={handleSignUp}
              >
                Sign Up
              </Button>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="subscribe"
                  className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label
                  htmlFor="subscribe"
                  className="text-neutral-neutral_500__day_ font-extralight"
                >
                  I agree with terms & conditions
                </label>
              </div>

              <div className="flex gap-5 self-stretch">
                <Button
                  shape="round"
                  leftIcon={<Img src="images/img_image_157.png" alt="Google Icon" className="h-5 w-5" />}
                  className="w-full gap-2.5 border border-solid  border-neutral-neutral_border_day_"
                >
                  Google Account
                </Button>
                <Button
                  shape="round"
                  leftIcon={
                    <Img
                      src="images/twitter-x-logo-.png"
                      alt="Twitter Icon"
                      className="h-7 w-7"
                    />
                  }
                  className="w-full gap-2.5 border border-solid border-neutral-neutral_border_day_"
                >
                  X Account
                </Button>
              </div>

              <div className="p-4">
                <div className="flex items-center space-x-2">
                  <p className="text-blue-gray-400">
                    <span className="text-neutral-neutral_1100_day_">
                      Already have an account?
                    </span>
                  </p>
                  <Link 
                    to="/lightauthenticationlogin" 
                    className="text-deep_purple-400 bg-deep-purple-500 font-normal py-1 px-3 rounded hover:bg-deep-purple-600 "
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
