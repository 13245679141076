import { Heading } from "../../components/HomeCom";
import UserProfile from "../../components/HomeCom/UserProfile";
import React from "react";

export default function HowItWorksSection() {
  return (
    <section className="relative flex flex-col items-center py-12 lg:py-1">
      <div className="container-xs flex flex-col items-center gap-6 lg:gap-12">
        <Heading
          size="heading5xl"
          as="h2"
          className="!font-manrope capitalize tracking-[-1.80px] md:text-[40px] sm:text-[34px] text-center"
        >
          How It Works
        </Heading>
        <div className="flex flex-wrap justify-center gap-10 lg:gap-20">
          
          <UserProfile className="lg:w-[80%] md:w-[30%] sm:w-full" />
          <UserProfile className="lg:w-[80%] md:w-[30%] sm:w-full" />
          <UserProfile className="lg:w-[80%] md:w-[30%] sm:w-full" />
          <UserProfile className="lg:w-[80%] md:w-[30%] sm:w-full" />
          {/* Puedes agregar más UserProfiles aquí si es necesario */}
        </div>
      </div>
    </section>
  );
}
