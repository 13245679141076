// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/PoppinsSemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/PoppinsRegular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Poppins";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-weight: 600;
}
@font-face {
    font-family: "Poppins";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
    font-family: "Poppins";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAA6C;IAC7C,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,4CAA6C;AACjD;AACA;IACI,sBAAsB;IACtB,4CAA4C;AAChD","sourcesContent":["@font-face {\n    font-family: \"Poppins\";\n    src: url(\"/public/fonts/PoppinsSemiBold.ttf\");\n    font-weight: 600;\n}\n@font-face {\n    font-family: \"Poppins\";\n    src: url(\"/public/fonts/PoppinsSemiBold.ttf\");\n}\n@font-face {\n    font-family: \"Poppins\";\n    src: url(\"/public/fonts/PoppinsRegular.ttf\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
