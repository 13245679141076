// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.custom-button {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    margin: 1px;
    color: white;
    background-size: cover;
    background-position: center;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-shadow: 1px 1px 2px black;
}

.custom-button:hover {
    opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Arbitrage/Buttons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".button-container {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    padding: 10px;\n}\n\n.custom-button {\n    width: 100%;\n    padding: 5px;\n    font-size: 14px;\n    margin: 1px;\n    color: white;\n    background-size: cover;\n    background-position: center;\n    border: none;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n    text-shadow: 1px 1px 2px black;\n}\n\n.custom-button:hover {\n    opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
