import React from "react";
// @ts-ignore
import SwitchProvider from "@dhiwise/react-switch";

type SwitchProps = {
  value?: string | boolean;
  className?: string;
  checkedIcon?: React.ReactNode;
  uncheckedIcon?: React.ReactNode;
  onChange?: (val: string | boolean) => void;
};

const Switch: React.FC<SwitchProps> = ({
  value = false,
  className = "",
  checkedIcon = <></>,
  uncheckedIcon = <></>,
  onChange,
}) => {
  const [selected, setSelected] = React.useState(value);

  const handleChange = (val: string | boolean) => {
    setSelected(val);
    onChange?.(val);
  };

  return (
    <div className={className}>
      <SwitchProvider
        checked={selected}
        onChange={handleChange}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
      />
    </div>
  );
};

export { Switch };
