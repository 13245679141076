import { Helmet } from "react-helmet";
import { Img, Button, Heading } from "../../components/HomeCom";
import Header from "../../components/HomeCom/Header";
import ContactFormSection from "./ContactFormSection";
import FAQSection from "./FAQSection";
import FeaturesSection from "./FeaturesSection";
import HowItWorksSection from "./HowItWorksSection";
import PopularCryptocurrenciesSection from "./PopularCryptocurrenciesSection";
import TestimonialSection from "./TestimonialSection";
import React from "react";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Crypto Exchange - Secure & Efficient Trading | Cryco</title>
        <meta
          name="description"
          content="Join Cryco, the trusted crypto exchange platform. Secure digital assets, instant transactions, and a comprehensive asset selection for an unmatched trading experience. Start trading Bitcoin, Ethereum, and more today."
        />
      </Helmet>

      <div className="relative bg-[url(/public/Images/HomePag-img/Body1.png)] bg-deep_purple-a400 bg-cover bg-no-repeat py-1 lg:py-1 w-full">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center gap-20 sm:gap-8">
            <Header />

            <div className="flex flex-col items-center text-center">
              <Heading
                size="heading6xl"
                as="h3"
                className="text-center text-2xl md:text-4xl lg:text-5xl font-manrope leading-[110px] tracking-[-1.80px] md:text-3xl sm:text-2xl"
              >
                Cheat codes for <br />
                sports betting.
              </Heading>

              <div className="relative mt-[2px] flex w-full max-w-md bg-gradient3">
                <div className="h-[40px] w-full bg-gradient" />
              </div>
            </div>

            <Heading
              size="text2xl"
              as="h4"
              className="text-center text-lg md:text-xl lg:text-2xl leading-[34px] text-indigo-100"
            >
              Welcome to our trusted Cryco crypto exchange. Security is paramount. Trade with confidence.
            </Heading>

            <div className="flex flex-row gap-4 sm:flex-row sm:gap-8 justify-center">
              <Button
                size="3xl"
                variant="gradient"
                color="blue_A200_purple_A200"
                rightIcon={
                  <div className="flex h-3 w-3 items-center justify-center">
                    <Img src="images/HomePag-img/Arrow 2-2.png" alt="Arrow Left" className="h-3 w-3" />
                  </div>
                }
                className="w-full max-w-xs rounded-[34px] font-bold"
              >
                Get Started
              </Button>
              <Button
                color="gray_900_02"
                size="3xl"
                rightIcon={
                  <div className="flex h-3 w-3 items-center justify-center">
                    <Img
                      src="images/HomePag-img/Arrow 2-2.png"
                      alt="Arrow Left"
                      className="h-3 w-3"
                    />
                  </div>
                }
                className="w-full max-w-xs rounded-[34px] border border-solid border-white-a700_01 font-bold"
              >
                Explore More
              </Button>
            </div>

            <div className="flex flex-col items-center gap-10 mt-1">
              <Img
                src="./images/HomePag-img/Group 546.png"
                alt="Product Image"
                className="w-[20%] h-auto md:w-[30%] lg:w-[30%] lg:top-[58%] absolute left-[-1px] top-[42%]"  // Ajusta la posición de la imagen
              />
            </div>



            <div className="  w-full max-w-screen-lg bg-deep_purple-a400_2d px-4 rounded-[20px] border border-solid border-white-a700_47">
            <div className="flex justify-center w-full mt-1">
              <div className="w-full max-w-screen-lg   justify-center">
                <div className="rounded-[18px] bg-white-a700_01">
                  <Img
                    src="./images/HomePag-img/Dash.png"
                    alt="Screens"
                    className="w-full h-auto rounded-lg object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="w-full bg-deep_purple-a400">
        <div className="relative min-h-screen w-full">
          <div className="flex flex-col items-center px-4 sm:px-6 lg:px-8 w-full">
            <div className="relative mt-[-20px] flex flex-col items-center md:mt-[60px] w-full">
              <div className="h-[80px] w-full bg-gradient blur-[1000.00px] backdrop-opacity-[0.5]" />

            </div>
            <div className="relative flex flex-col items-center justify-end w-full">
              <Heading
                as="h1"
                className="text-center uppercase mb-8 md:mb-6 text-3xl md:text-4xl lg:text-5xl"
              >
                DATA FROM THE BEST
              </Heading>
              <div className="relative flex w-full max-w-screen-xl bg-gradient py-16 px-4 md:px-10 blur-[500.00px] backdrop-opacity-[0.5]">
                <Img src="imgsave" alt="Save Image" className="h-4 mt-8" />
              </div>
            </div>

            <Img
              src="Save"
              alt="Save Icon"
              className="absolute bottom-[15%] mx-auto h-[16px]"
            />
            <div className="relative flex flex-wrap justify-center gap-4 px-4 sm:px-6 lg:px-8 w-full">
              {["Coinxy", "Tether", "NEAR Protocol", "Huobi", "Coinxy", "Wrapped Bitcoin", "Solana", "Crypto"].map((item, index) => (
                <div key={index} className="flex justify-center rounded-lg bg-gray-900_02 p-4">
                  <Img src={`images/HomePag-img/${item}.png`} alt={`${item} Image`} className="h-8 object-contain" />
                </div>
              ))}
            </div>

            <div className="relative mt-[400px] py-6 lg:py-1 w-full">
              <div className="absolute bottom-0 left-0 right-0 h-2 w-full max-w-screen-xl bg-gradient blur-[1000.00px] backdrop-opacity-[1.5]" />
              <HowItWorksSection />
            </div>

            <div className="relative mt-[180px] py-6 lg:py-8 w-full">
              <div className="absolute bottom-0 left-0 right-0 h-1/2 w-full max-w-screen-xl bg-gradient blur-[1000.00px] backdrop-opacity-[1.5]" />
              <FeaturesSection />
            </div>

            <PopularCryptocurrenciesSection />

            <div className="relative mt-[100px] py-1 lg:py-20 w-full">
              <div className="absolute bottom-5 left-0 right-0 h-1/2 w-full max-w-screen-xl bg-gradient blur-[1000.00px] backdrop-opacity-[0.5]" />
              <TestimonialSection />
              <div className="items-end mt-[-500px]">
                <div className="h-[938px] w-full bg-gradient blur-[500.00px] backdrop-opacity-[0.5]" />
                <FAQSection />
              </div>

              <div className="relative z-1 bg-[url(/public/Images/HomePag-img/Body.png)] bg-cover bg-no-repeat py-40 lg:py-20 w-full">
                <Img
                  src="./images/HomePag-img/Round ranner.png"
                  alt="Round Banner"
                  className="absolute left-0 right-0 mx-auto h-[150px] w-[150px] object-cover md:h-[200px] md:w-[200px] lg:top-[-50px] lg:h-[250px] lg:w-[250px]"
                />
                <ContactFormSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
