import { Button, Img, Heading } from "../../HomeCom";

interface Props {
  className?: string;
}
const handleButtonClick = () => {
  window.location.href = '/lightauthenticationlogin';
};

export default function Header({  ...props }: Props) {
  return (
    <header
      {...props}
      className={`${props.className} flex xs:flex-col self-stretch justify-between items-start ml-2 gap-5 md:ml-0`}
    >
      <div className="mt-3 flex w-[18%] justify-center gap-3 self-end md:w-full sm:self-auto">
        <Img
          src="images/HomePag-img/Logo Dark.png"
          alt="Logo Image"
          className="h-[60px] w-[226px] object-cover"
        />

      </div>
      <div className="flex items-center gap-[30px] lg:flex-col">
        <ul className="!mb-2.5 flex flex-wrap gap-2 self-end md:self-auto">
          <li>
            <a href="/#">
              <Heading size="headingmd" as="h6" className="!font-arimo">
                Home
              </Heading>
            </a>
          </li>
          <li>
            <a href="/#">
              <Heading size="headingmd" as="h6" className="!font-arimo">
                Features
              </Heading>
            </a>
          </li>
          <li>
            <a href="/#">
              <Heading size="headingmd" as="h6" className="!font-arimo">
                Integration
              </Heading>
            </a>
          </li>
          <li>
            <a href="/#">
              <Heading size="headingmd" as="h6" className="!font-arimo">
                Blog
              </Heading>
            </a>
          </li>
          
          <li>
            <a href="/#">
              <Heading size="headingmd" as="h6" className="!font-arimo">
                Contact
              </Heading>
            </a>
          </li>
        </ul>
        <Button
         onClick={handleButtonClick}
          color="gray_900_02"
          shape="round"
          leftIcon={
            <div className="flex h-[18px] w-[12px] items-center justify-center">
              <Img
                src="images/HomePag-img/user.png"
                alt="User"
                className="mb-1 h-[18px] w-[12px] object-cover"
              />
            </div>
          }
          className="min-w-[102px] gap-2 border border-solid border-blue_gray-800 font-bold capitalize"
        >
          Login
        </Button>
      </div>
    </header>
  );
}
