import { Button } from "./Button";
import ClientStatistics from "./ClientStatistics";
import DecentralizedIdentityVerification from "./DecentralizedIdentityVerification";
import Header from "./Header";
import { Heading } from "./Heading";
import { Img } from "./Img";
import { Input } from "./Input";
import { RatingBar } from "./RatingBar";
import { ReactTable } from "./ReactTable";
import { Text } from "./Text";
import { TextArea } from "./TextArea";
import UserProfile from "./UserProfile";
import UserProfile1 from "./UserProfile1";


export { Button,ClientStatistics,DecentralizedIdentityVerification,Header, Heading, Img, Input, RatingBar, ReactTable, Text, TextArea,UserProfile1,UserProfile};

