import React from "react";

type CloseSVGProps = React.SVGProps<SVGSVGElement> & Partial<{
  fillColor: string;
  className: string;
}>;

const CloseSVG: React.FC<CloseSVGProps> = ({
  fillColor = "#000000",
  className = "",
  ...props
}) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      height={props.height || 20}
      width={props.width || 20}
      viewBox={`0 0 ${props.width || 20} ${props.height || 20}`}
      {...props}
    >
      <path d="M4.707 3.293L3.293 4.707 10 11.414 16.707 4.707 15.293 3.293 10 8.586 4.707 3.293zM4.707 16.707L3.293 15.293 10 8.586 16.707 15.293 15.293 16.707 10 11.414 4.707 16.707z" />
    </svg>
  );
};

export { CloseSVG };
